<template>
  <div>
    <CreateCollectionActive/>
  </div>
</template>

<script>
import CreateCollectionActive from "../../components/collection/CollectionListActive.vue";
import { confirmBox } from "../../utils/confirmBox";

export default {
  components: { CreateCollectionActive },
};
</script>
