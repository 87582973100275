<template>
  <div @click="openCollection">
    <div class="md-layout-item">
      <md-card class="collection-list__card">
        <div class="header">
          <div class="md-layout paddings">
            <div
              class="collection-style-number font-weight-bold md-size-25 md-xsmall-size-100"
            >
              <truncate-hovering :text="collection.name" :length="50" />
            </div>
            <div
              class="collection-style-number collection_number font-weight-bold md-size-25 md-xsmall-size-100"
            >
              {{ collection.collection_number }}
            </div>
            <div
              class="collection-product-type font-weight-bold md-size-25 md-xsmall-size-100"
            >
              {{ collection.start_date }}
            </div>
            <div class="date md-size-25 md-xsmall-size-100">
              <span>
                Created at
                <span class="font-weight-bold">{{
                  $moment(collection.created_at).format("HH:mm Do MMMM Y")
                }}</span>
              </span>
            </div>
          </div>
        </div>
        <md-card-content>
          <div class="collection-container" style="padding: 0px 25px">
            <div class="embed">
              <embed
                v-if="!imageLoading"
                class="centered-and-cropped"
                :src="defaultImageUrl"
                alt="sample image"
              />
            </div>
            <div v-if="count > 0" class="unread-messages">
              <v-badge color="teal accent-4" :content="count">
                <v-icon large color="teal accent-4"> mdi-message-text </v-icon>
              </v-badge>
            </div>
            <v-divider vertical class="vertical-hr" />
            <v-slide-group
              class="pa-4 min-collection-container"
              multiple
              show-arrows="mobile"
            >
              <v-slide-item
                v-for="inquiry in collection.inquiries"
                :key="inquiry.id"
              >
                <mini-inquiry :inquiry="inquiry" :inquiryShow="true" />
              </v-slide-item>
              <v-slide-item v-if="isAllowedToCreateInquiry">
                <div class="createBtn-container">
                  <button-card
                    class="btn"
                    @click="openInquiry(collection.id, collection.brand_id)"
                  >
                    <ellipse-icon
                      class="button_card_icon"
                      :width="`45px`"
                      :height="`45px`"
                      ><v-icon style="color: #582963" large
                        >add</v-icon
                      ></ellipse-icon
                    >
                    <span>Create New Style</span>
                  </button-card>
                </div>
              </v-slide-item>
            </v-slide-group>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import MiniInquiry from "../Inquiry/MiniInquiry.vue";
import auth from "../../auth";
import utils from "../../utils";
import * as conf from "../../conf.yml";
import restAdapter from "../../restAdapter";

export default {
  name: "CollectionList",
  components: {
    MiniInquiry,
  },
  data: () => ({
    sampleImage: require(`../../assets/collection.png`),
    acceptedThumbnailTypes: utils.acceptedThumbnailTypes,
    baseUrl: "",
    defaultImageUrl: "",
    imageLoading: true,
    count: null,
  }),

  mounted() {
    this.baseUrl = conf.default.server.host;
    this.getDefaultImage();
    this.getUnreadMessageCount();
  },
  props: {
    collection: {
      type: [Object, null],
    },
    changeCollection: {
      type: Boolean,
      default: false,
    },
    newStyleButtonShow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isAllowedToCreateInquiry() {
      return (
        auth.user.account.organization_registry_id ===
          this.collection.brand_id ||
        auth.user.account.organization_registry_id ===
          this.collection.created_by_organization_id
      );
    },
  },
  created() {
    const next = document.getElementsByClassName("v-slide-group__next");
    const prev = document.getElementsByClassName("v-slide-group__prev");

    for (let i = 0; i < next.length; i++) {
      next[i].addEventListener("click", function (event) {
        event.stopPropagation();
      });
    }

    for (let i = 0; i < prev.length; i++) {
      prev[i].addEventListener("click", function (event) {
        event.stopPropagation();
      });
    }
  },

  destroyed() {
    const next = document.getElementsByClassName("v-slide-group__next");
    const prev = document.getElementsByClassName("v-slide-group__prev");

    for (let i = 0; i < next.length; i++) {
      next[i].removeEventListener("click");
    }

    for (let i = 0; i < prev.length; i++) {
      prev[i].removeEventListener("click");
    }
  },

  methods: {
    // hoverArrow() {
    //   const nav = document.querySelector(".collections-nav");
    //   const left = document.querySelector(".arrow-container .left");
    //   const right = document.querySelector(".arrow-container .right");

    //   let idx;

    //   left.addEventListener("mouseenter", function () {
    //     idx = setInterval(() => (nav.scrollLeft -= 1), 5);
    //   });

    //   left.addEventListener("mouseleave", function () {
    //     clearInterval(idx);
    //   });

    //   right.addEventListener("mouseenter", function () {
    //     idx = setInterval(() => (nav.scrollLeft += 1), 5);
    //   });

    //   right.addEventListener("mouseleave", function () {
    //     clearInterval(idx);
    //   });
    // },

    openInquiry(collectionId, brandId) {
      this.$router.push({
        name: "Inquiry",
        params: { id: "new" },
        query: { collectionId: collectionId },
      });
    },

    getDefaultImage() {
      if (
        !this.collection.thumbnail ||
        !this.isSupportedFileType(this.collection.thumbnail.upload_name)
      ) {
        this.defaultImageUrl = this.sampleImage;
        this.imageLoading = false;
      } else {
        restAdapter
          .getWithOutCache(
            `/api/collections/file_show/${this.collection.thumbnail.upload_name}`
          )
          .then((response) => {
            this.defaultImageUrl = response.data.file;
            this.imageLoading = false;
          })
          .catch((error) => {
            this.defaultImageUrl = this.sampleImage;
            this.imageLoading = false;
          });
      }
    },

    getUnreadMessageCount() {
      this.collectionId = this.collection.id;
      restAdapter
        .get(`/api/collections/${this.collectionId}/unread_count`)
        .then((response) => {
          this.count = response.data.unread_count;
        });
    },

    isSupportedFileType(filename) {
      const extension =
        filename.match(/\.[0-9a-z]+$/i) &&
        filename.match(/\.[0-9a-z]+$/i)[0].toLowerCase();
      const supportedFileTypes = this.acceptedThumbnailTypes.split(",");
      return supportedFileTypes.includes(extension);
    },

    openCollection() {
      if (this.changeCollection) {
        this.$emit("change-collection", this.collection.id);
      } else {
        this.$router.push({ path: "/app/collection/" + this.collection.id });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a:not(.md-button):hover {
  text-decoration: none;
}

.collection-list__card {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
  margin-bottom: 25px;
}

.header {
  background: #473068;
  height: calc(100% - 50%);
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.icon-class {
  background-color: #473068;
  border-radius: 8px;
  color: #fff;
  height: 40px;
  width: 40px;
}

.collection-list__card:hover {
  background: #ffffff;
  box-shadow: 0px 17px 6px rgba(28, 45, 71, 0.15);
  border-radius: 7px;
  cursor: pointer;

  .header {
    background: #70d0ce;
    color: #473068;
  }
  .collection-style-number {
    color: #473068;
  }
  .collection-product-type {
    color: #473068;
  }
  .date {
    color: #473068;
  }
}
.collection-container {
  margin-bottom: 8px;
  display: flex;
}

.embed {
  margin-top: 26px;
  margin-bottom: 10px;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
}
.embed embed {
  justify-content: center;
  align-items: center;
  // border: 1px solid $tableBorder;
  border-radius: 7px;
  // background-color: #fff;
}
.centered-and-cropped {
  object-fit: cover;
  // height: 100%;
  width: 100%;
}
.vertical-hr {
  align-self: center;
  border-color: $primaryDark !important;
  height: 138px;
  margin-left: 4%;
  margin-right: 1%;
}
.paddings {
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 10px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.collection-style-number {
  color: #f3f1f1;
  text-align: left;
  font-family: sans-serif;
  font-weight: 500;
  width: 383px;
}

.collection_number {
  width: 150px;
}
.collection-product-type {
  color: #f3f1f1;
}

.date {
  color: #f3f1f1;
  text-align: right;
}

.arrow-parent-container {
  display: flex;
  justify-content: right;
}

.collections-nav {
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  gap: 15px;
  padding-left: 0rem;
  padding-bottom: 1rem;
  align-items: center;
  height: 180px;
}

.collections-nav li {
  background: #fff;
  border-radius: 50px;
  padding: 7px 20px;
  font-size: 0.75rem;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%);
  /* font-family: 'Objectivity-Medium', sans-serif; */
  font-family: "Open-Bold", sans-serif;
  color: #0a2540;
  list-style-type: none;
  cursor: pointer;
}

.collections-nav li:hover {
  background: #0a2540;
  opacity: 0.95;
  transition: 0.3s ease-in-out;
  color: #fff;
}

.collections-nav .active {
  background: #0a2540;
  color: #fff;
}

.collections-nav::-webkit-scrollbar {
  height: 7px !important;
  /* width of the entire scrollbar */
}
body {
  scroll-behavior: smooth;
}
.collections-nav::-webkit-scrollbar-track {
  background: #f6f9fc;
  /* color of the tracking area */
  border-radius: 10px;
}

.collections-nav::-webkit-scrollbar-thumb {
  background-color: #0a2540;
  /* color of the scroll thumb */
  border-radius: 10px;
}
.min-collection-container {
  width: 82%;
}

.min-collection-container::v-deep {
  .v-slide-group__content {
    gap: 25px;
  }

  .v-icon.v-icon {
    font-size: 30px;
  }

  .theme--light.v-icon {
    color: #473068;
  }

  .v-slide-group__next {
    justify-content: right !important;
  }

  .v-slide-group__prev {
    justify-content: left !important;
  }
}
.unread-messages {
  margin-top: 2px !important;
  margin-right: -37px;
}
.v-icon {
  font-size: 31px !important;
}

@media only screen and (max-width: 1260px) {
  .v-icon {
    font-size: 25px !important;
  }
}
.createBtn-container {
  display: flex;
  align-items: center;
}
.createButton {
  color: $tableBorder !important;
  text-decoration: none !important;
}
.btn {
  height: 90px;
  width: auto;
  padding: 10px;
  font-size: 15px;
  display: flex;
}
.btn:after {
  width: 0px !important;
}
.button_card_icon {
  float: left;
  margin: 0 15px;
}
</style>
